/* Add these styles to your CSS file */



#text-logo{
  width: 125px;
}

#logo {
  width: 90px;
  height: 90px;
  animation: flip 40s infinite linear;
  transform-style: preserve-3d;
}
@media (max-width: 700px) {
  .lg\:hidden {
    display: none;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:space-x-4 > :not(:last-child) {
    margin-right: 1rem;
  }
  #text-logo{
    width: 125px;
    position: absolute;
    left: 20%;
    top: -0.2%;
    /* margin-top: -50px; */
  }
}

@media (max-width: 450px) {
  .lg\:hidden {
    display: block;
  }
  .lg\:flex {
    display: none
  }
  #text-logo{
    width: 125px;
    position: absolute;
    left: 17%;
    top: -0.2%;
    /* margin-top: -50px; */
  }
}
