body {
  background-color: #f8f9fa;
}

.login-form {
  background-color: white;
}

.login-form h2 {
  font-family: 'Arial', sans-serif;
  color: #5981a5;
}

.login-form .form-control {
  border-radius: 0.25rem;
}

.login-form .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-special{
  background-color: #5981a5 !important ;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
}

/* Notification.css */
.notification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.notification-card {
  background: white;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  font-family: 'Arial, sans-serif';
}

.notification-card h2 {
  color: #333;
  margin-bottom: 20px;
}

.notification-card p {
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .notification-card {
    padding: 15px 20px;
  }
}

@media (max-width: 480px) {
  .notification-card {
    padding: 10px 15px;
  }
}
