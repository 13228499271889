

.card {
  background-color: #E0F7FA !important;
  border: none !important;
  color: #014080 !important
}
.btn-special-cry{
  background-color: #0056b3 !important;
  border: none !important;
}
.card * .btn{
  border: none !important;
  background-color: #0056b3 !important;
}
.card * .btn:hover{
  border: none !important;
  background-color: #438cda !important;
  color: beige !important;
}
.row> * .btn{
  border: none !important;
  background-color: #0056b3 !important;
}
.row> * .btn:hover{
  border: none !important;
  background-color: #438cda !important;
  color: beige !important;
}
#test-line{
  border-bottom: 1px solid #E0F7FA !important;
}
.flashing-dot-red {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  /* height: 10px;
  width: 10px;
  background-color: red; */
  border-radius: 50%;
  /* animation: flash 2s infinite; */
}
.flashing-dot-red2 {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 35px; /* Adjust as needed */
  /* height: 10px;
  width: 10px;
  background-color: red; */
  border-radius: 50%;
  /* animation: flash 2s infinite; */
}
.flashing-dot-green {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  /* height: 10px;
  width: 10px; */
  /* background-color: green; */
  border-radius: 50%;
  /* animation: flash2 2s infinite; */
}
.flashing-dot-green2 {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 35px; /* Adjust as needed */
  /* height: 10px;
  width: 10px; */
  /* background-color: green; */
  border-radius: 50%;
  /* animation: flash2 2s infinite; */
}
.date-corner {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 45px; /* Adjust as needed */
  height: 10px;
  width: 10px;
  font-weight: 500;
}

@keyframes flash {
  0% { opacity: 1; }
  10% { opacity: 0.9; }
  20% { opacity: 0.8; }
  30% { opacity: 0.7; }
  40% { opacity: 0.6; }
  50% { opacity: 0.0; }
  60% { opacity: 0.6; }
  70% { opacity: 0.7; }
  80% { opacity: 0.8; }
  90% { opacity: 0.9; }
  100% { opacity: 1; }
}
@keyframes flash2 {
  0% { opacity: 1; }
  10% { opacity: 0.9; }
  20% { opacity: 0.8; }
  30% { opacity: 0.7; }
  40% { opacity: 0.6; }
  50% { opacity: 0.0; }
  60% { opacity: 0.6; }
  70% { opacity: 0.7; }
  80% { opacity: 0.8; }
  90% { opacity: 0.9; }
  100% { opacity: 1; }
}

.modal-content {
  border-radius: 5px;
  background-color: #E0F7FA !important;
  color: #014080 !important;
}
#gifff{
  width: 30px;
}

.loading-animation {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 0.6s linear infinite;
  margin-right: 8px; /* Spacing before 'Loading...' text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
