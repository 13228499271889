.terms-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  color: #E0F7FA !important;
}

.terms-container h1, .terms-container h2 {
  color: #E0F7FA !important;
}

.terms-container h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.terms-container h2 {
  font-size: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.terms-container p {
  margin: 10px 0;
}
