body{
  background-color: rgb(3, 28, 48)!important;
  color: #add2e4 !important;
}
.bg-gray-900{
  background-color: rgb(3, 28, 48)!important;
}
.btn{
background-color: rgb(90, 16, 210) !important;
}

.app {
  margin: 2.5%;
}
.results{
  vertical-align: middle;
}
.table-container{
  border-radius: 10px!important;
  border: none !important;
}
.table-tr>th{
  color: #ecb719 !important;
  background-color: #7a7985 !important;
}
.table-tr2>td{
  color: #7a7985;
  background-color: rgba(243, 243, 243, 0.5) !important;
  font-weight: bold;
  color: #327898 !important;
}
.btn-special{
  background-color: #5981a5  ;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
}
.special{
  background-color: #cc0000;
  border: none !important;
  font-weight: 600;
}
.special-reverse{
  background-color: #0c3c4c;
  border: none !important;
  font-weight: 600;
}
.btn-special:hover{
  background-color: #00c190;
  border: none !important;
  background-color: #ad891b !important;
  font-weight: 400 !important;
}
.btn-custom{
  width: 115px;
  border: none !important;
  background-color: #ecb618 !important;
  font-weight: 500 !important;
}
.btn-special:hover{
  background-color: #559555;
  border: none;
  color: #ecb719;
  font-weight: 600;
}
#logout{
  position: absolute;
  right: 3%;
}
#toast{
  position: fixed;
  left: 50%;
  top: 5%;
  z-index: 9999;
  padding: 16px 8px;
  transform: translate(-50%,-50%);
  min-width: 120px;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  background-color: rgba(0,0,0,0.5);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#ffa516);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}
.table-locale {
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 5px;
}

.txt-cent{
  text-align: center !important;
}

.tr-locale>th{
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: none !important;
  color: #e65830 !important;
  background-color: #384867 !important;
}
.tr-locale>td{
  vertical-align: middle;
  padding-left: 15px !important;
  color: rgba(50,120,152);
  background-color: rgba(243, 243, 243, 0.5) !important;
  font-weight: bold;
  color: #327898 !important;
}
.th-1{
  border-radius: 10px 0px 0px 0px;
}
.th-2{
  border-radius: 0px 10px 0px 0px;
}
.success{
  margin-left: 275px;
  color: #00c190;
  font-weight: bold;
}
:root {
  --borderWidth: 7px;
  --height: 24px;
  --width: 12px;
  --borderColor: #78b13f;
}
.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

#priceWithdraw{
  color: #085348 !important
}

#provider-payment{
  width: 50px !important;
  vertical-align: middle;
}

/* CSS-only info box on hover
 * Ken Sparby, @kensparby */

 *,
 *::before,
 *::after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
 }
 
 .hover-container {
   position: relative;
 }
 
 .hover-target {
   position: relative;
   font-size: 2rem;
 }
 
 .hover-popup {
   position: absolute;
   display: none;
   flex-direction: column;
   justify-content: space-between;
   top: 70%;
   left: 5%;
   width: 50ch;
   margin: min(1rem, 20px);
   font-size: .8rem;
   background-color: #fff;
   border-radius: 8px;
   padding: 1.5em;
   z-index: 42;
   transform: scale(1);
   transition: transform 200ms ease;
   transform-origin: 8% -10px;
 }
 
 .hover-target:hover + .hover-popup,
 .hover-target:focus + .hover-popup,
 .hover-popup:hover{
   transform: scale(1);
 }
 
 .hover-popup :not(:first-child) {
   margin-top: 1rem;
 }
 
 .hover-popup span {
   color: rgb(200, 20, 0);
   font-weight: 700;
 }
 
 .hover-popup::before {
 /* This is the triangle/arrow */
   content: '';
   position: absolute;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   border-bottom: 10px solid #fff;
   top: -10px;
 }
 
 .hover-popup::after {
   /* This is merely here to expand the hoverable area, as a buffer between the "Hover me" text and the popup. */
   content: '';
   position: absolute;
   top: -1rem;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
 }
 #info_user{
   display: none;
 }
 @media (prefers-reduced-motion: reduce) {
   *,
   ::before,
   ::after {
     animation-delay: -1ms !important;
     animation-duration: -1ms !important;
     animation-iteration-count: 1 !important;
     background-attachment: initial !important;
     scroll-behavior: auto !important;
     transition-duration: 0s !important;
     transition-delay: 0s !important;
   }
 }

 /* src/FloatingButtons.css */
.floating-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  flex-direction: column;
  gap: 10px;
}

.floating-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
#floating-buttons{
  display: none;
}

.floating-button:hover {
  background-color: #0056b3;
}

#custom-textArea{
  height: 500px !important;
}

#marked{
  background-color: rgba(50,120,152) !important;
  color: white !important;
}

.nav-link{
  color: #ecb618 !important;
  font-weight: bold !important;
}
.navbar-brand{
  color: #ecb618 !important;
  font-weight: bold !important;
  font-size: 25px !important;
}
.legend{
  font-weight: 500;
  cursor: pointer;
}
.legend:hover{
  font-weight: 500;
  color: rgb(25, 61, 78) !important;
  text-decoration-line: underline;
}

.floating-container-accounts {
  position: fixed;
  top: 90px;
  left: 30px;
  z-index: 9999999999;
}
#count-pyaments{
  font-size: 20px;
  font-weight: bold;
}


.before-title{
  font-weight: 400 !important;
}

.replies-css{
  font-size: 25px;
  font-weight: 500;
  text-decoration: underline;
}

.sender{
  font-weight: bold;
}
.sender-1{
  font-weight: 600;
}

#textarea{
  height: 150px !important;
}

#dropdown-basic{
  background-color: rgba(50,120,152) !important;
  z-index: 999999 !important;
}

#selected-id{
  background-color: rgba(50,120,152) !important;
  color: cornsilk !important;
}

/* @media (max-width: 768px) {


  .floating-button {
    margin-bottom: 10px;
  }


  .th-2{
    border-radius: 0px 0px 0px 0px;
  }
  .tr-locale {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
  }

  .tr-locale > td, .tr-locale > th {
    width: 100% !important;
    text-align: center;
    padding: 0.5rem;
  }

  .btn-special {
    margin-bottom: 10px;
  }
  .floating-container {
    position: fixed;
    top: 80px;
    right: 20px;
    display: none;
    flex-direction: column;
    gap: 10px;
  }
  .app {
    padding-top: 10%;
  }
} */
#img-social{
  width: 200px !important;
  height: auto
}

.button{
  background-color: #09eab4 !important;
}

.redPercent{
  color: #cc0000 !important
}
.greenPercent{
  color: #55a464 !important
}
.link{
  cursor: pointer;
}

.link:hover{
  text-decoration: underline;
}

.bold-span{
  font-weight: bold;
}

/* @media (max-width: 768px) {
  body{
    width: 100%;
  }
  .table-tr>th {
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
    background: white;
  }
  .table-container {
    overflow: auto;
  }
} */

@media screen and (orientation:landscape)
and (min-device-width: 319px) 
and (max-device-width: 940px) {

  .table-tr>th {
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
    background: white; 
  }
  .table-container {
    width: 1000px;
  }
}

.clickAble{
  cursor: pointer;
}

#underline{
  border-bottom: 1px solid #328994 !important;
}

#special-card{
  /* height: 200px; */
  
}
#special-text{
  overflow-y: scroll;
  height: 100px;
}
#special-text::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

#special-text::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
  border-radius: 5px;
}

#special-text::-webkit-scrollbar-thumb {
  background: rgb(196, 193, 193); /* Color of the thumb */
  border-radius: 5px;
}

#special-text::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#ffa516);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13{ 
  100%{transform: rotate(1turn)}
}

.round-two{
  border-radius: 15px !important;
}

.exclaimer {
  background-color: #e94560;
  color: #ffffff;
  padding: 15px;
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  position: relative; /* Add this to position the close button correctly */
  animation: fadeIn 1s ease-in infinite;
}
.close-btn {
  position: absolute;
  top: 10%;
  right: 0.1%; /* Adjust this value as needed to align the button */
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}
.close-btn:hover {
  color: #ffffffaa;
}
.instruction-how{
  margin-left:65px;
  margin-right: 65px;
}

@media (max-width: 768px) {
  .instruction-how{
    margin-left:5px;
    margin-right: 5px;
  }
  .exclaimer {
      font-size: 1em;
  }
}

@keyframes slideIn {
  from {
      transform: translateY(-100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
}
20% {
    opacity: 0.5;
}
50% {
    opacity: 1;
}
80% {
    opacity: 0.5;
}
100% {
    opacity: 0;
}
}

